import { gql } from "@apollo/client";
import { ORG_FRAGMENT } from "./organizations";

export const CURRENT_USER_FRAGMENT = gql`
  ${ORG_FRAGMENT}
  fragment CurrentUserFragment on User {
    id
    firstName
    lastName
    email

    organizationMembership {
      role
      organizationId
    }
    organization {
      ...OrgFragment
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_FRAGMENT}
  {
    currentUser {
      ...CurrentUserFragment
    }
  }
`;

export const UPDATE_CURRENT_USER_MUTATION = gql`
  mutation UpdateCurrentUser(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    updateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;
