export const APP_NAME = "impact-web";

export const APP_VERSION = process.env.REACT_APP_GIT_SHA || "";

export const IsProduction = () =>
  process.env.REACT_APP_VERCEL_ENV === "production" || false;

export const IsStaging = () =>
  process.env.REACT_APP_VERCEL_ENV === "preview" || false;

export const GetSentryEnvironment = () => {
  if (IsProduction()) {
    return "production";
  }
  if (IsStaging()) {
    return "staging";
  }
  return "development";
};

export const GetSentryEnabled = () => {
  return GetSentryEnvironment() !== "development";
};

export const StripeClientId = () => {
  if (IsProduction()) {
    return "ca_Mu6ltctZuFDnAP29YJnzxWxgrLki6YdQ";
  }

  if (IsStaging()) {
    return "ca_Mu6lRzFUWYyzo0MksujeIog6fUWTA4jR";
  }

  return "ca_Mu6lRzFUWYyzo0MksujeIog6fUWTA4jR";
};

export const ProductionAppUrl = "https://app.getimpactlocal.com";
export const StagingAppUrl = "https://staging-app.getimpactlocal.com";
export const DevelopmentAppUrl = "http://localhost:3000";

export const GetActiveAppUrl = () => {
  if (IsProduction()) {
    return ProductionAppUrl;
  }
  if (IsStaging()) {
    return StagingAppUrl;
  }
  return DevelopmentAppUrl;
};

export const ProductionApiUrl = "https://api.getimpactlocal.com/query";
export const StagingApiUrl = "https://staging-api.getimpactlocal.com/query";
export const DevelopmentApiUrl = "http://localhost:4001/query";

export const GetActiveAPIUrl = () => {
  if (IsProduction()) {
    return ProductionApiUrl;
  }
  if (IsStaging()) {
    return StagingApiUrl;
  }
  return DevelopmentApiUrl;
};

export const ProductLandingUrl = "https://getimpactlocal.com";

export const SUPPORT_EMAIL = "support@getimpactlocal.com";

export const PRIVACY_URL = "https://www.getimpactlocal.com/privacy";
export const USER_TERMS_URL = "https://www.getimpactlocal.com/userterms";
export const NONPROFIT_TERMS_URL =
  "https://www.getimpactlocal.com/nonprofitterms";
export const DONOTSELL_URL = "https://www.getimpactlocal.com/donotsellshare";

export const TERMS_LINKS = {
  user: { link: USER_TERMS_URL, title: "User Terms" },
  privacy: { link: PRIVACY_URL, title: "Privacy Policy" },
  nonprofit: { link: NONPROFIT_TERMS_URL, title: "Nonprofit Terms" },
};

export const STRIPE_PUBKEY_DEV =
  "pk_test_51K4VQfFViSwjMzMhoU6E7GkxHXyIfoq8yziVJ5xT7oEPQsZ1fgwbGQV3WFanszDIuQjnuz9coIEkmnyL3KuLz8nc00y4VxL5U0";

export const STRIPE_PUBKEY_PROD =
  "pk_live_51K4VQfFViSwjMzMhsKsCDkELyRLb46i7Ft6qAFKB5wdDymsur63CBught0FV4NJQuj6eUFnocsNVDAahR1FTVJ4d00ttfBdnPw";

export const STRIPE_PUBKEY = IsProduction()
  ? STRIPE_PUBKEY_PROD
  : STRIPE_PUBKEY_DEV;
