import moment from "moment";

export const LoginErrorMsg =
  "There was an error logging in, please check your email and password and try again.";

export function getInitialsString(firstName, lastName) {
  const firstInitial = firstName?.charAt(0);
  const lastInitial = lastName?.charAt(0);

  return `${firstInitial || ""}${lastInitial || ""}`;
}

export function getNameString(firstName, lastName) {
  return `${firstName || ""} ${lastName || ""}`;
}

export function formatAmountCents(amountCents) {
  if (isNaN(amountCents)) {
    return amountCents;
  }

  return "$" + (amountCents / 100).toFixed(2);
}

export function formatCurrency(amountDollars) {
  if (isNaN(amountDollars)) {
    return amountDollars;
  }

  return "$" + Intl.NumberFormat().format(amountDollars);
}

export function formatDate(date) {
  return moment(date).format("MMM D, YYYY, h:mma");
}
