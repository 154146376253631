import React from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";

import EditOrgProfile from "../../components/EditOrgProfile";
import WithAppLayout from "../../components/WithAppLayout";
import pricing from "../../assets/img/pricing.png";

function SettingsPage({ data, error, loading, currentUser }) {
  const org = currentUser?.organization;

  return (
    <Flex direction={{ base: "column", xl: "row" }} pt={{ base: "90px" }}>
      <Flex direction="column" width="stretch" maxW={"1000px"}>
        <Flex
          direction="column"
          bg="white"
          bgSize="cover"
          py={{ base: "30px", md: "56px" }}
          px={{ base: "30px", md: "64px" }}
          borderRadius="30px"
          borderWidth={2}
          borderColor="#3311DB"
          marginBottom={{ base: "30px" }}
        >
          {loading && <Spinner size="xl" color="primary.500" />}
          <Text fontSize="2xl" fontWeight="700" mb="20px">
            Organization Profile
          </Text>
          <EditOrgProfile org={org} showSummary={true} />
        </Flex>

        <Flex
          direction="column"
          bg="white"
          bgSize="cover"
          py={{ base: "30px", md: "56px" }}
          px={{ base: "30px", md: "64px" }}
          borderRadius="30px"
          borderWidth={2}
          borderColor="#3311DB"
          marginBottom={{ base: "30px" }}
        >
          <Text fontSize="2xl" fontWeight="700" mb="20px">
            Pricing &amp; Features
          </Text>
          <img src={pricing} alt="pricing" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WithAppLayout(SettingsPage);
