import React from "react";
import { Link, Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FaChevronLeft } from "react-icons/fa";

import Footer from "../components/footer/FooterAuth";
import { ProductLandingUrl } from "../helpers/config";

export default function AuthLayout({ children }) {
  return (
    <>
      <Box>
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Flex position="relative" h="max-content">
            <Flex
              h={{
                sm: "initial",
                md: "unset",
                lg: "100vh",
                xl: "97vh",
              }}
              w="100%"
              maxW={{ md: "66%", lg: "1313px" }}
              mx="auto"
              pt={{ sm: "50px", md: "0px" }}
              px={{ lg: "30px", xl: "0px" }}
              ps={{ xl: "70px" }}
              justifyContent="start"
              direction="column"
            >
              <Link
                href={ProductLandingUrl}
                style={{
                  width: "fit-content",
                  marginTop: "40px",
                }}
              >
                <Flex
                  align="center"
                  ps={{ base: "25px", lg: "0px" }}
                  pt={{ lg: "0px", xl: "0px" }}
                  w="fit-content"
                >
                  <Icon
                    as={FaChevronLeft}
                    me="12px"
                    h="13px"
                    w="8px"
                    color="secondaryGray.600"
                  />
                  <Text ms="0px" fontSize="sm" color="secondaryGray.600">
                    Back
                  </Text>
                </Flex>
              </Link>

              {children}
              <Box
                display={{ base: "none", md: "block" }}
                h="100%"
                minH="100vh"
                w={{ lg: "50vw", "2xl": "44vw" }}
                position="absolute"
                right="0px"
              >
                <Flex
                  bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
                  justify="center"
                  align="end"
                  w="100%"
                  h="100%"
                  bgSize="cover"
                  bgPosition="50%"
                  position="absolute"
                ></Flex>
              </Box>
              <Footer />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
