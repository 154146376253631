import React from "react";
import { Flex, SimpleGrid, Text, Button, Spinner } from "@chakra-ui/react";
import InputField from "../../components/fields/InputField";

import WithAppLayout from "../../components/WithAppLayout";
import { UPDATE_CURRENT_USER_MUTATION } from "../../graphql/users";
import ImpactApolloClient from "../../services/ImpactApolloClient";

function AccountPage({ data, error, loading, currentUser }) {
  const [firstName, setFirstName] = React.useState(currentUser?.firstName);
  const [lastName, setLastName] = React.useState(currentUser?.lastName);
  const [email, setEmail] = React.useState(currentUser?.email);

  React.useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const onSaveButtonPress = async () => {
    try {
      const r = await ImpactApolloClient.mutate({
        mutation: UPDATE_CURRENT_USER_MUTATION,
        variables: { firstName, lastName, email },
      });
      console.log("Update user response", r);
      alert("Success! Your profile has been updated.");
    } catch (e) {
      console.log("Login Error:", e);
      alert("Error", "There was an error updating your profile.");
    }
  };

  return (
    <Flex direction={{ base: "column", xl: "row" }} pt={{ base: "90px" }}>
      <Flex direction="column" width="stretch" maxW={"1000px"}>
        <Flex
          direction="column"
          bg="white"
          bgSize="cover"
          py={{ base: "30px", md: "56px" }}
          px={{ base: "30px", md: "64px" }}
          borderRadius="30px"
          borderWidth={2}
          borderColor="#3311DB"
          marginBottom={{ base: "30px" }}
        >
          {loading && <Spinner size="xl" color="primary.500" />}
          <Text fontSize="2xl" fontWeight="700" mb="20px">
            Account
          </Text>
          <SimpleGrid columns={{ base: "1" }} gap="20px">
            <InputField
              mb="0px"
              placeholder="First name"
              label="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputField
              mb="0px"
              placeholder="Last name"
              label="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <InputField
              mb="0px"
              placeholder="Email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Flex align="center">
              <Button
                bg="brand.500"
                color="white"
                _hover={{ bg: "brand.400" }}
                _active={{ bg: "brand.300" }}
                _focus={{ bg: "brand.600" }}
                fontWeight="500"
                fontSize="18px"
                py="25px"
                px="32"
                me="38px"
                onClick={onSaveButtonPress}
              >
                Save
              </Button>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WithAppLayout(AccountPage);
