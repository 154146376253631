import React from "react";
import { Link, Box, Flex, Icon, Text } from "@chakra-ui/react";

import Footer from "../components/footer/FooterApp";
import colors from "../helpers/colors";

export default function DonateLayout({ children }) {
  return (
    <Box bg={colors.greyLight} minH="100vh">
      <Flex position="relative" minH="100vh">
        <Flex
          minH="100vh"
          w="100%"
          pt={{ sm: "25px" }}
          px={{ sm: "15px" }}
          justifyContent="start"
          direction="column"
        >
          {children}
          <Flex flex={1} />
          <Footer />
        </Flex>
      </Flex>
    </Box>
  );
}
