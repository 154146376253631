import React, { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import _ from "lodash";
import { Flex, Spinner } from "@chakra-ui/react";

import "./stripe.css";
import DonationSuccess from "./DonationSuccess";
import ConfirmDonateV2Form from "./ConfirmDonateV2Form";

export default function CheckoutFormV2({
  stripePaymentIntentClientSecret,
  publicOrgInfo,
  coverTransactionCosts,
  totalTransactionCost,
  grandTotal,
  donationAmount,
  isSubscription,
  checkoutSessionToken,
  paymentMethods,
}) {
  const stripe = useStripe();
  const elements = useElements({
    clientSecret: stripePaymentIntentClientSecret,
  });

  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(
    function () {
      if (!stripe) {
        return;
      }

      if (
        !stripePaymentIntentClientSecret ||
        !stripePaymentIntentClientSecret.length
      ) {
        return;
      }

      stripe
        .retrievePaymentIntent(stripePaymentIntentClientSecret)
        .then(({ paymentIntent }) => {
          //   console.log("paymentIntent", paymentIntent);
          setPaymentIntent(_.cloneDeep(paymentIntent));
        });
    },
    [stripe, stripePaymentIntentClientSecret]
  );

  const renderBody = () => {
    // if we just started the checkout process, we don't have a clientSecret yet

    const confirmDonateForm = (
      <ConfirmDonateV2Form
        stripe={stripe}
        elements={elements}
        publicOrgInfo={publicOrgInfo}
        donationAmountCents={donationAmount}
        transactionCostsCents={totalTransactionCost}
        grandTotalCents={grandTotal}
        coverTransactionCosts={coverTransactionCosts}
        isSubscription={isSubscription}
        checkoutSessionToken={checkoutSessionToken}
        paymentMethods={paymentMethods}
      />
    );

    if (!stripePaymentIntentClientSecret || !paymentIntent) {
      return confirmDonateForm;
    }

    switch (paymentIntent.status) {
      case "succeeded":
        return (
          <DonationSuccess
            paymentIntent={paymentIntent}
            publicOrgInfo={publicOrgInfo}
            grandTotalCents={grandTotal}
            isSubscription={isSubscription}
          />
        );

      case "processing":
        return (
          <div id="payment-message">
            Your payment is processing, please refresh the page.
          </div>
        );

      default:
        return confirmDonateForm;
    }
  };

  return renderBody();
}
