import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import _ from "lodash";
import { Text } from "@chakra-ui/react";

import { GetActiveAppUrl } from "../../helpers/config";
import { DONATE_V2_ADD_PAYMENT_PATH, DONATE_V2_PATH } from "../../routes";
import colors from "../../helpers/colors";

const GetPaymentStatusText = (setupIntent) => {
  switch (setupIntent?.status) {
    case "succeeded":
      return "Success! Your payment method has been saved.";

    case "processing":
      return "Processing payment details. We'll update you when processing is complete.";
    case "requires_payment_method":
      // Redirect your user back to your payment page to attempt collecting
      // payment again
      // return "Failed to process payment details. Please try another payment method.";
      return "";
    default:
      return "";
  }
};

export default function SetupForm({ clientSecret }) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [setupIntent, setSetupIntent] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      console.log("setupIntent", setupIntent);
      if (setupIntent) {
        setSetupIntent(_.cloneDeep(setupIntent));
      }
    });
  }, [stripe, clientSecret]);

  // Handle form submission.
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: GetActiveAppUrl() + DONATE_V2_PATH + window.location.search,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const paymentElementOptions = {
    layout: "accordion",
  };

  return (
    <form onSubmit={handleSubmit}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Add a new payment method
      </Text>
      <div>{GetPaymentStatusText(setupIntent)}</div>
      <div
        id="payment-message"
        style={{
          color: colors.red,
          marginTop: 0,
          marginBottom: 10,
          paddingTop: 0,
        }}
      >
        {errorMessage}
      </div>

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      <button
        disabled={!stripe || !elements}
        id="submit"
        className="submit-button"
      >
        <span id="button-text">Save Payment Method</span>
      </button>
    </form>
  );
}
