import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    auth {
      login(email: $email, password: $password)
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $termsConsentAccepted: Boolean!
  ) {
    auth {
      register(
        input: {
          email: $email
          password: $password
          firstName: $firstName
          lastName: $lastName
          termsConsentAccepted: $termsConsentAccepted
        }
      )
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation RequestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const COMPLETE_RESET_PASSWORD_MUTATION = gql`
  mutation CompleteResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    completeResetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    )
  }
`;
