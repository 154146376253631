import React, { useEffect, useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Spinner } from "@chakra-ui/react";

import "./stripe.css";
import { GetActiveAppUrl } from "../../helpers/config";
import CheckoutAmountCard from "./CheckoutAmountCard";
import colors from "../../helpers/colors";

export default function PaymentForm({
  stripe,
  elements,
  publicOrgInfo,
  donationAmountCents,
  transactionCostsCents,
  grandTotalCents,
  coverTransactionCosts,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: GetActiveAppUrl() + "/donate" + window.location.search,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An unexpected error occurred: " + error?.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "accordion",
  };

  return (
    <>
      <CheckoutAmountCard
        publicOrgInfo={publicOrgInfo}
        donationAmountCents={donationAmountCents}
        transactionCostsCents={transactionCostsCents}
        grandTotalCents={grandTotalCents}
        coverTransactionCosts={coverTransactionCosts}
      />
      <form id="payment-form" onSubmit={handleSubmit}>
        {errorMessage && (
          <div
            id="payment-message"
            style={{
              color: colors.red,
              marginTop: 0,
              marginBottom: 10,
              paddingTop: 0,
            }}
          >
            {errorMessage}
          </div>
        )}
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="submit-button"
        >
          <span id="button-text">
            {isLoading ? (
              <Spinner size="sm" color="primary.500" />
            ) : (
              "Complete donation"
            )}
          </span>
        </button>

        <a
          href="impactlocal://editdonation"
          className="submit-button edit-button"
        >
          Edit donation
        </a>
      </form>
    </>
  );
}
