import React, { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import _ from "lodash";

import "./stripe.css";
import DonationSuccess from "./DonationSuccess";
import PaymentForm from "./PaymentForm";

export default function CheckoutForm({
  clientSecret,
  orgId,
  publicOrgInfo,
  coverTransactionCosts,
  totalTransactionCost,
  grandTotal,
  donationAmount,
}) {
  const stripe = useStripe();
  const elements = useElements({ clientSecret });

  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      //   console.log("paymentIntent", paymentIntent);
      setPaymentIntent(_.cloneDeep(paymentIntent));
    });
  }, [stripe, clientSecret]);

  const renderBody = () => {
    if (!paymentIntent) {
      return null;
    }

    switch (paymentIntent.status) {
      case "succeeded":
        return (
          <DonationSuccess
            paymentIntent={paymentIntent}
            publicOrgInfo={publicOrgInfo}
            grandTotalCents={grandTotal}
          />
        );
      case "processing":
        return <div id="payment-message">Your payment is processing.</div>;
      default:
        return (
          <PaymentForm
            stripe={stripe}
            elements={elements}
            publicOrgInfo={publicOrgInfo}
            donationAmountCents={donationAmount}
            transactionCostsCents={totalTransactionCost}
            grandTotalCents={grandTotal}
            coverTransactionCosts={coverTransactionCosts}
          />
        );
    }
  };

  return renderBody();
}
