import React from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

import AuthLayout from "../../layouts/AuthLayout";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { COMPLETE_RESET_PASSWORD_MUTATION } from "../../graphql/auth";
import ImpactApolloClient from "../../services/ImpactApolloClient";

function ResetCompletePassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [showSuccess, setShowSuccess] = React.useState(false);

  // get token from url params
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const onCompleteResetPasswordButtonPress = () => {
    if (!password || !confirmPassword) {
      alert("Please fill out all fields");
      return;
    }

    ImpactApolloClient.mutate({
      mutation: COMPLETE_RESET_PASSWORD_MUTATION,
      variables: {
        password,
        passwordConfirmation: confirmPassword,
        resetPasswordToken: token,
      },
    })
      .then((res) => {
        console.log(res);

        setShowSuccess(true);
      })
      .catch((err) => {
        console.log(err);

        alert("Error", "There was an error with your request: " + err?.message);
      });
  };

  return (
    <AuthLayout>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px", lg: "100px", xl: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", lg: "16vh", xl: "20vh" }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          {logo && (
            <img
              src={logo}
              style={{ height: "35px", marginBottom: "34px" }}
              alt="logo"
            />
          )}

          <Heading
            color={textColor}
            fontSize={{ base: "3xl", md: "36px" }}
            mb="16px"
          >
            Finish resetting your password
          </Heading>
          {!showSuccess ? (
            <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
              Enter your new password below
            </Text>
          ) : (
            <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
              Your password has been reset successfully!
            </Text>
          )}
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", lg: "456px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          align="start"
        >
          {!showSuccess && (
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="password"
                mb="24px"
                size="lg"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="password"
                mb="24px"
                size="lg"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={onCompleteResetPasswordButtonPress}
              >
                Submit
              </Button>
            </FormControl>
          )}
          <NavLink to="/login">
            <Text
              color={textColorBrand}
              as="span"
              ms="5px"
              fontWeight="500"
              fontSize="14px"
            >
              Return to sign in
            </Text>
          </NavLink>
        </Flex>
      </Flex>
    </AuthLayout>
  );
}

export default ResetCompletePassword;
