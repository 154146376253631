import React from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";

import WithAppLayout from "../../components/WithAppLayout";
import WithQuery from "../../components/WithQuery";
import { ORG_DONATION_HISTORY_QUERY } from "../../graphql/organizations";
import SearchTable from "../../components/SearchTable";
import Card from "../../components/card/Card";
import {
  getNameString,
  formatAmountCents,
  formatDate,
} from "../../helpers/strings";

const renderEmpty = () => (
  <Flex
    direction="column"
    bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
    bgSize="cover"
    py={{ base: "30px", md: "56px" }}
    px={{ base: "30px", md: "64px" }}
    borderRadius="30px"
  >
    <Text
      fontSize={{ base: "24px", md: "34px" }}
      color="white"
      mb="14px"
      maxW={{
        base: "100%",
      }}
      fontWeight="700"
      lineHeight={{ base: "32px", md: "42px" }}
    >
      Reports
    </Text>
    <Text
      fontSize="md"
      color="#E3DAFF"
      maxW={{
        base: "100%",
        md: "64%",
        lg: "40%",
        xl: "56%",
        "2xl": "46%",
        "3xl": "34%",
      }}
      fontWeight="500"
      mb="40px"
      lineHeight="28px"
    >
      Reports will be available after your first donation is processed through
      the platform.
    </Text>
  </Flex>
);

const columnsData = [
  {
    Header: "Donation Amount",
    accessor: "amount",
  },
  {
    Header: "Total Fees (incl. Stripe)",
    accessor: "fees",
  },
  {
    Header: "Net Donation",
    accessor: "netDonation",
  },
  {
    Header: "Donor Covered Fees",
    accessor: "userCoveredFees",
  },
  {
    Header: "Donor Name",
    accessor: "name",
  },
  {
    Header: "Donor Email",
    accessor: "email",
  },
  {
    Header: "Donor Marketing Opt-In",
    accessor: "commsOptIn",
  },
  {
    Header: "Billing Postal Code",
    accessor: "billingPostalCode",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Stripe Payment Id",
    accessor: "stripePaymentIntentId",
  },
];

// Format for CSV export
columnsData.forEach((item) => {
  item.key = item.accessor;
  item.label = item.Header;
});

const formatDonationHistoryTableData = (donationHistory) => {
  return donationHistory?.map((item) => {
    return {
      id: item.id,
      amount: formatAmountCents(item.totalChargeCents),
      fees: formatAmountCents(item.totalTransactionFeesCents),
      netDonation: formatAmountCents(
        item.totalChargeCents - item.totalTransactionFeesCents
      ),

      name: getNameString(
        item?.userReport?.firstName,
        item?.userReport?.lastName
      ),
      email: item?.userReport?.email,

      userCoveredFees: item.userCoveredFees ? "Yes" : "No",

      commsOptIn: item?.userReport?.communicationOptInAt ? "Yes" : "No",

      billingPostalCode: item?.billingPostalCode || "-",

      date: formatDate(item.createdAt),

      stripePaymentIntentId: item.stripePaymentIntentId,
    };
  });
};

function ReportsPageInner({ data, error, loading, refetch, currentUser }) {
  const donationHistory = data?.organizationDonationHistory;

  const formattedTableData = formatDonationHistoryTableData(donationHistory);

  return (
    <Flex direction={{ base: "column", xl: "row" }} pt={{ base: "90px" }}>
      <Flex direction="column" width="stretch">
        {loading && <Spinner size="xl" color="primary.500" />}
        {donationHistory?.length ? (
          <Card px="0px">
            <SearchTable
              tableData={formattedTableData}
              columnsData={columnsData}
            />
          </Card>
        ) : loading ? null : (
          renderEmpty()
        )}
      </Flex>
    </Flex>
  );
}

function ReportsPage({ children }) {
  return WithQuery(ReportsPageInner, ORG_DONATION_HISTORY_QUERY, null, {
    children,
  })();
}

export default WithAppLayout(ReportsPage);
