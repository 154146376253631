import React from "react";

import { Flex } from "@chakra-ui/react";

import logo from "../../../assets/img/logo_text.png";

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      {logo && <img src={logo} style={{ height: "35px" }} alt="logo" />}
    </Flex>
  );
}

export default SidebarBrand;
