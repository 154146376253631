import React from "react";

import { Flex, SimpleGrid, Text, Button } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import InputField from "./fields/InputField";
import ImpactApolloClient from "../services/ImpactApolloClient";
import { UPDATE_ORG_PROFILE_MUTATION } from "../graphql/organizations";

const renderHqString = (org) => {
  const hqString = org?.city ? `${org?.city}, ${org?.state}` : "not listed";
  return hqString;
};

export default function EditOrgProfile({
  ref,
  org,
  showSummary,
  isOnboarding,
}) {
  const [tagline, setTagline] = React.useState(org?.tagline || "");
  const [postDonationMessage, setCustomPostDonationMessage] = React.useState(
    org?.postDonationMessage || ""
  );

  const [overrideStreetAddress1, setOverrideStreetAddress1] = React.useState(
    org?.overrideStreetAddress1 || null
  );
  const [overrideStreetAddress2, setOverrideStreetAddress2] = React.useState(
    org?.overrideStreetAddress2 || null
  );
  const [overrideCity, setOverrideCity] = React.useState(
    org?.overrideCity || null
  );
  const [overrideState, setOverrideState] = React.useState(
    org?.overrideState || null
  );
  const [overrideZipCode, setOverrideZipCode] = React.useState(
    org?.overrideZipCode || null
  );

  React.useEffect(() => {
    setTagline(org?.tagline || "");
    setCustomPostDonationMessage(org?.postDonationMessage || "");

    setOverrideStreetAddress1(org?.overrideStreetAddress1 || null);
    setOverrideStreetAddress2(org?.overrideStreetAddress2 || null);
    setOverrideCity(org?.overrideCity || null);
    setOverrideState(org?.overrideState || null);
    setOverrideZipCode(org?.overrideZipCode || null);
  }, [org]);

  const onSaveClicked = () => {
    // build customAddressInput
    let customAddressInput = null;
    if (overrideStreetAddress1) {
      customAddressInput = {};

      customAddressInput.streetAddress1 = overrideStreetAddress1;
    }
    if (overrideStreetAddress2) {
      customAddressInput.streetAddress2 = overrideStreetAddress2;
    }
    if (overrideCity) {
      customAddressInput.city = overrideCity;
    }
    if (overrideState) {
      customAddressInput.state = overrideState;
    }
    if (overrideZipCode) {
      customAddressInput.zipCode = overrideZipCode;
    }

    ImpactApolloClient.mutate({
      mutation: UPDATE_ORG_PROFILE_MUTATION,
      variables: {
        orgProfileUpdateInput: {
          tagline,
          postDonationMessage,
          customAddressInput: customAddressInput,
        },
      },
    })
      .then((res) => {
        console.log(res);
        alert("Saved!");
      })
      .catch((err) => {
        console.log("err", err);
        Sentry.captureException(err);

        alert("Error saving", err);
      });
  };

  if (!org) {
    return (
      <Text fontSize="xl" fontWeight="700" mb={"10px"}>
        No organization found
      </Text>
    );
  }

  return (
    <SimpleGrid columns={{ base: "1" }} gap="20px">
      {showSummary && (
        <>
          <Flex flexDirection="row" alignItems="flex-end">
            {org?.logoURL && (
              <img
                style={{ height: 100, width: "auto", marginRight: 15 }}
                src={org?.logoURL}
              />
            )}
            <Text fontSize="xl" fontWeight="700" mb={"10px"}>
              {org?.name} (EIN: {org?.ein})
            </Text>
          </Flex>
          <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
            <b>NTEE code:</b> {org?.candidNteeCode}
          </Text>
          <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
            <b>HQ:</b> {renderHqString(org)}
          </Text>
          <Text color="secondaryGray.600" fontSize="md" fontWeight="400">
            <b>Mission:</b> {org?.mission}
          </Text>
        </>
      )}

      <Text color="primaryBlack.600" fontSize="md" fontWeight="400">
        <b>Profile settings</b>
      </Text>
      <InputField
        mb="0px"
        placeholder={`${org?.mission}`}
        label="Short Description (max. 200 characters)"
        value={tagline}
        onChange={(e) => setTagline(e.target.value)}
        maxLength={200}
      />

      <InputField
        mb="0px"
        placeholder={`${org?.name} thanks you for your support!`}
        label="Donor Thank You Message"
        value={postDonationMessage}
        onChange={(e) => setCustomPostDonationMessage(e.target.value)}
        maxLength={500}
      />

      {!isOnboarding && (
        <>
          <Text color="primaryBlack.600" fontSize="md" fontWeight="400">
            <b>Custom address:</b>
          </Text>

          <Text color="primaryBlack.600" fontSize="sm" fontWeight="400">
            We have your organization's address from your Candid profile as{" "}
            <b>
              {org?.streetAddress1},{" "}
              {org?.streetAddress2 ? org?.streetAddress2 + ", " : ""}
              {org?.city}, {org?.state} {org?.zipCode}
            </b>
            . If you'd like to override this, please enter your custom address
            below.
          </Text>

          <InputField
            mb="0px"
            placeholder={org?.streetAddress1}
            label="Street Address 1"
            value={overrideStreetAddress1}
            onChange={(e) => setOverrideStreetAddress1(e.target.value)}
          />

          <InputField
            mb="0px"
            placeholder={org?.streetAddress2}
            label="Street Address 2"
            value={overrideStreetAddress2}
            onChange={(e) => setOverrideStreetAddress2(e.target.value)}
          />

          <InputField
            mb="0px"
            placeholder={org?.city}
            label="City"
            value={overrideCity}
            onChange={(e) => setOverrideCity(e.target.value)}
          />

          <InputField
            mb="0px"
            placeholder={org?.state}
            label="State"
            value={overrideState}
            onChange={(e) => setOverrideState(e.target.value)}
          />

          <InputField
            mb="0px"
            placeholder={org?.zipCode}
            label="Zip Code"
            value={overrideZipCode}
            onChange={(e) => setOverrideZipCode(e.target.value)}
          />
        </>
      )}

      <Flex align="center">
        <Button
          bg="brand.500"
          color="white"
          _hover={{ bg: "brand.400" }}
          _active={{ bg: "brand.300" }}
          _focus={{ bg: "brand.600" }}
          fontWeight="500"
          fontSize="18px"
          py="25px"
          px="32"
          me="38px"
          onClick={onSaveClicked}
        >
          Save
        </Button>
      </Flex>
    </SimpleGrid>
  );
}
