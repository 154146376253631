import { Box, Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import NavbarLinksApp from "../../components/navbar/NavbarLinksApp";

export default function NavbarApp(props) {
  const { brandText, currentUser } = props;

  let mainText = useColorModeValue("navy.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("whiteAlpha.500", "rgba(11,20,55,0.5)");
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={"flex"}
      minH="75px"
      justifyContent={{ base: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      zIndex="20"
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100vw - 5%)",
        xl: "calc(100vw - 340px)",
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          base: "row",
        }}
        alignItems={{ base: "center" }}
        mb={gap}
      >
        <Link
          color={mainText}
          href="#"
          bg="inherit"
          borderRadius="inherit"
          fontWeight="bold"
          fontSize="34px"
          _hover={{ color: { mainText } }}
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
        >
          {brandText}
        </Link>
        <Box ms="auto" w={{ base: "unset" }}>
          <NavbarLinksApp
            onOpen={props.onOpen}
            fixed={props.fixed}
            currentUser={currentUser}
          />
        </Box>
      </Flex>
    </Box>
  );
}
