import ImpactLocalStorage from "./ImpactLocalStorage";
// eslint-disable-next-line import/no-cycle
import ImpactApolloClient from "./ImpactApolloClient";
import { LOGIN_MUTATION, REGISTER_MUTATION } from "../graphql/auth";
import { LoginErrorMsg } from "../helpers/strings";
import * as Sentry from "@sentry/react";

const CURRENT_USER_KEY = "currentUser";

export async function Login({ email, password }) {
  return ImpactApolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: { email, password },
  })
    .then((r) => {
      const token = r?.data?.auth?.login?.token;
      const userId = r?.data?.auth?.login?.userId;

      // Set localstorage data
      if (token && userId) {
        // Chain the localstorage promise
        return ImpactLocalStorage.save({
          key: CURRENT_USER_KEY,
          data: {
            token,
            userId,
          },
        });
      }
      console.log("Login response userid or token is null");
      throw new Error(LoginErrorMsg);
    })
    .catch((e) => {
      Sentry.captureException(e);

      console.log("Login Error:", e);
      alert(LoginErrorMsg);
    });
}

export async function Register({
  firstName,
  lastName,
  email,
  password,
  termsConsentAccepted,
}) {
  return ImpactApolloClient.mutate({
    mutation: REGISTER_MUTATION,
    variables: {
      firstName,
      lastName,
      email,
      password,
      termsConsentAccepted,
    },
  })
    .then((r) => {
      const token = r?.data?.auth?.register?.token;
      const userId = r?.data?.auth?.register?.userId;

      // Set localstorage data
      if (token && userId) {
        // Chain the localstorage promise
        return ImpactLocalStorage.save({
          key: CURRENT_USER_KEY,
          data: {
            token,
            userId,
          },
        });
      }
      console.log("Register response userid or token is null");
      throw new Error(LoginErrorMsg);
    })
    .catch((e) => {
      Sentry.captureException(e);

      console.log("Register Error:", e);
      throw new Error(LoginErrorMsg);
    });
}

export const Logout = async () => {
  // Clear localstorage token
  return ImpactLocalStorage.remove({
    key: CURRENT_USER_KEY,
  })
    .then(() => {
      // Clear apollo state, return chained promise
      ImpactApolloClient.resetStore();
      window.location.href = "/";
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log("Error in ImpactAuth.Logout", err);
      throw err;
    });
};

export async function GetLocalAuthData() {
  let localAuthData = null;

  await ImpactLocalStorage.load({
    key: CURRENT_USER_KEY,
  })
    .then((ret) => {
      if (!ret) {
        return null;
      }

      localAuthData = {};
      localAuthData.token = ret?.token;
      localAuthData.userId = ret?.userId;
    })
    .catch((err) => {
      Sentry.captureException(err);

      console.log(err.message);
    });

  return localAuthData;
}

export async function GetLocalAuthToken() {
  const localAuthData = await GetLocalAuthData();
  return localAuthData ? localAuthData?.token : null;
}

export async function IsAuthd() {
  const token = await GetLocalAuthToken();
  return !!token;
}
