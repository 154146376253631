import React from "react";

// Chakra imports
import { Flex, Text, Link } from "@chakra-ui/react";
import { SUPPORT_EMAIL } from "../../helpers/config";

export default function CharityCheckErrorBanner({ createOrg }) {
  return (
    <Flex
      direction="column"
      bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
      bgSize="cover"
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius="30px"
    >
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        Charity Check Failed
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="28px"
      >
        The Charity Check provided by Candid for your organization has failed.
        Please contact us at
        <Link
          href={`mailto:${SUPPORT_EMAIL}`}
          fontWeight="bold"
          style={{ display: "inline" }}
        >
          {" "}
          {SUPPORT_EMAIL}{" "}
        </Link>
        if you have questions or believe this is an error.
      </Text>
    </Flex>
  );
}
