import React from "react";
import { useQuery } from "@apollo/client";

const WithQuery = (WrappedComponent, query, variables, moreProps) => {
  return function inner(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, error, data, refetch } = useQuery(query, {
      variables,
      fetchPolicy: "network-only",
    });

    return (
      <WrappedComponent
        data={data}
        loading={loading}
        error={error}
        refetch={refetch}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...moreProps}
      />
    );
  };
};

export default WithQuery;
