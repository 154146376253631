import React from "react";

// Chakra imports
import { Button, Flex, Text } from "@chakra-ui/react";

export default function WelcomeBanner({ createOrg }) {
  return (
    <Flex
      direction="column"
      bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
      bgSize="cover"
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius="30px"
    >
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        Welcome to Impact Local!
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="28px"
      >
        Your organization is activated - you can now start accepting donations.
        You'll see more here after your first donation is processed through the
        platform.
      </Text>
    </Flex>
  );
}
