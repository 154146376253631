import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ImpactApolloClient from "./services/ImpactApolloClient";
import {
  GetSentryEnvironment,
  GetSentryEnabled,
  APP_VERSION,
} from "./helpers/config";

import LoginPage from "./pages/public/Login";
import RegisterPage from "./pages/public/Register";
import ResetPasswordPage from "./pages/public/ResetPassword";
import ResetCompletePassword from "./pages/public/ResetCompletePassword";
import DonatePage from "./pages/public/DonatePage";
import SetupPage from "./pages/public/SetupPage";

import DonateV2Page from "./pages/public/DonateV2Page";
import DonateV2AddPaymentPage from "./pages/public/DonateV2AddPaymentPage";

import PrivateRoute from "./pages/PrivateRoute";
import DashboardPage from "./pages/private/Dashboard";

import ReportsPage from "./pages/private/ReportsPage";
import SettingsPage from "./pages/private/SettingsPage";

import ilTheme from "./theme/theme";
import "./assets/css/App.css";

import {
  LOGIN_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  DASHBOARD_PATH,
  REPORTS_PATH,
  SETTINGS_PATH,
  ACCOUNT_PATH,
  DONATE_PATH,
  RESET_COMPLETE_PASSWORD_PATH,
  SETUP_PATH,
  DONATE_V2_PATH,
  DONATE_V2_ADD_PAYMENT_PATH,
} from "./routes";
import AccountPage from "./pages/private/AccountPage";

Sentry.init({
  dsn: "https://58aa69e3591a4de6b082808de5523c2b@o1308189.ingest.sentry.io/6553365",
  release: APP_VERSION,
  environment: GetSentryEnvironment(),
  autoSessionTracking: true,
  enabled: GetSentryEnabled(),

  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <ApolloProvider client={ImpactApolloClient}>
      <BrowserRouter>
        <ChakraProvider theme={ilTheme}>
          <Routes>
            {/* Public routes */}
            <Route path={LOGIN_PATH} element={<LoginPage />} />
            <Route path={REGISTER_PATH} element={<RegisterPage />} />
            <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
            <Route
              path={RESET_COMPLETE_PASSWORD_PATH}
              element={<ResetCompletePassword />}
            />

            <Route path={DONATE_PATH} element={<DonatePage />} />
            <Route path={SETUP_PATH} element={<SetupPage />} />

            <Route path={DONATE_V2_PATH} element={<DonateV2Page />} />
            <Route
              path={DONATE_V2_ADD_PAYMENT_PATH}
              element={<DonateV2AddPaymentPage />}
            />

            {/* Private routes */}
            <Route path="/" element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                element={<Navigate to={DASHBOARD_PATH} />}
              />
              <Route path={DASHBOARD_PATH} element={<DashboardPage />} />
              <Route path={REPORTS_PATH} element={<ReportsPage />} />
              <Route path={SETTINGS_PATH} element={<SettingsPage />} />
              <Route path={ACCOUNT_PATH} element={<AccountPage />} />
            </Route>
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
