import React from "react";
import AppLayout from "../layouts/AppLayout";

const WithAppLayout = (WrappedComponent) => {
  return function inner(props) {
    return (
      <AppLayout>
        <WrappedComponent />
      </AppLayout>
    );
  };
};

export default WithAppLayout;
