/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { ProductLandingUrl, SUPPORT_EMAIL } from "../../helpers/config";
import TermsAndPolicyLinks from "../TermsAndPolicyLinks";

export default function FooterApp() {
  const textColor = useColorModeValue("gray.400", "white");

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px={{ base: "10px" }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        {" "}
        &copy;
        <Text as="span" fontWeight="500" ms="4px">
          Impact Local, Inc.
        </Text>
      </Text>
      <List
        display="flex"
        textAlign={"center"}
        alignItems="center"
        justifyContent={"center"}
      >
        <ListItem
          me={{
            base: "10px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href={`mailto:${SUPPORT_EMAIL}`}
          >
            Support
          </Link>
        </ListItem>

        <TermsAndPolicyLinks />
      </List>
    </Flex>
  );
}
