import { Icon } from "@chakra-ui/react";
import {
  MdDashboard,
  MdSettings,
  MdOutlineTableChart,
  MdAccountBox,
} from "react-icons/md";

// Public routes
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const RESET_PASSWORD_PATH = "/reset";
export const RESET_COMPLETE_PASSWORD_PATH = "/reset-complete";

// Public, hidden routes
export const DONATE_PATH = "/donate";
export const SETUP_PATH = "/setup";

export const DONATE_V2_PATH = "/donateV2";
export const DONATE_V2_ADD_PAYMENT_PATH = "/donateV2/add-payment";

// Private routes
export const DASHBOARD_PATH = "/dashboard";
export const REPORTS_PATH = "/reports";
export const SETTINGS_PATH = "/settings";
export const ACCOUNT_PATH = "/account";

// Sidebar menu
export const sidebarRoutes = [
  {
    name: "Dashboard",
    path: DASHBOARD_PATH,
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Reports",
    path: REPORTS_PATH,
    icon: (
      <Icon
        as={MdOutlineTableChart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
  },
  {
    name: "Settings",
    path: SETTINGS_PATH,
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Account",
    path: ACCOUNT_PATH,
    icon: <Icon as={MdAccountBox} width="20px" height="20px" color="inherit" />,
  },
];

export const getActiveRouteName = () => {
  let activeRoute = "";

  for (let i = 0; i < sidebarRoutes.length; i++) {
    if (sidebarRoutes[i].collapse) {
      let collapseActiveRoute = getActiveRouteName(sidebarRoutes[i].items);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else if (sidebarRoutes[i].category) {
      let categoryActiveRoute = getActiveRouteName(sidebarRoutes[i].items);
      if (categoryActiveRoute !== activeRoute) {
        return categoryActiveRoute;
      }
    } else {
      if (window.location.href.indexOf(sidebarRoutes[i].path) !== -1) {
        return sidebarRoutes[i].name;
      }
    }
  }
  return activeRoute;
};
