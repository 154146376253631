import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Flex, Spinner } from "@chakra-ui/react";

import DonateLayout from "../../layouts/DonateLayout";
import { STRIPE_PUBKEY } from "../../helpers/config";
import CheckoutFormV2 from "../../components/stripe/CheckoutFormV2";
import WithQuery from "../../components/WithQuery";
import { CHECKOUT_SESSION_QUERY } from "../../graphql/checkout";

//   get url parameters payment_intent and token
const urlParams = new URLSearchParams(window.location.search);

// checkout session token
const checkoutSessionToken = urlParams.get("token");
const connectAccount = urlParams.get("connect_account");
// const platformAccount = urlParams.get("platform_account");

// if completing a checkout session, this should be the connected account id
// if creating a new payment method, this should be the platform account id
// Since this is the donate page, we are completing a checkout session, and this should be the connected account id
const stripePromise = loadStripe(STRIPE_PUBKEY, {
  stripeAccount: connectAccount,
});

function DonateV2Inner({ data, loading, error }) {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    // only need clientSecret on the payment setup page?
    // clientSecret: stripeClientSecret,

    appearance,
  };

  const checkoutSession = data?.donationCheckoutSession;
  const publicOrgInfo = checkoutSession?.publicOrgInfo;

  const paymentAmountCents = checkoutSession?.paymentAmountCents;
  const coverTransactionCosts = checkoutSession?.coverTransactionCosts;
  const finalAmountToCharge = checkoutSession?.finalAmountToCharge;
  const transactionCost = checkoutSession?.transactionCost;

  const isSubscription = checkoutSession?.isSubscription;

  return (
    <Elements stripe={stripePromise} options={options}>
      <DonateLayout>
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {loading ? (
            <Spinner size="sm" color="primary.500" />
          ) : (
            <CheckoutFormV2
              coverTransactionCosts={coverTransactionCosts}
              totalTransactionCost={transactionCost}
              publicOrgInfo={publicOrgInfo}
              grandTotal={finalAmountToCharge}
              donationAmount={paymentAmountCents}
              isSubscription={isSubscription}
              checkoutSessionToken={checkoutSessionToken}
              stripePaymentIntentClientSecret={
                checkoutSession?.stripePaymentIntentClientSecret
              }
              paymentMethods={checkoutSession?.paymentMethods}
            />
          )}
        </Flex>
      </DonateLayout>
    </Elements>
  );
}

export default function Donate({ children }) {
  return WithQuery(
    DonateV2Inner,
    CHECKOUT_SESSION_QUERY,
    { token: checkoutSessionToken },
    {
      children,
    }
  )();
}
