import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "../components/footer/FooterApp";
// Layout components
import Navbar from "../components/navbar/NavbarApp";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../contexts/SidebarContext";
import React, { useState } from "react";
import { getActiveRouteName } from "../routes";
import WithQuery from "../components/WithQuery";
import { CURRENT_USER_QUERY } from "../graphql/users";

function AppLayoutInner(props) {
  const { children, data, loading, refetch, ...rest } = props;
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const currentUser = data?.currentUser;

  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 285px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 285px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                brandText={getActiveRouteName()}
                fixed={true}
                {...rest}
                currentUser={currentUser}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            pt={"30px"}
            px={{ base: "45px" }}
            minH="100vh"
            display={"flex"}
            flexDir={"column"}
            flex={1}
          >
            <Box flex={1} width={"100%"}>
              {/* embed current user prop on children, along with loading and refetch */}
              {React.cloneElement(children, { loading, currentUser, refetch })}
            </Box>
            <Box alignSelf={"flex-end"} width={"100%"}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}

export default function AppLayout({ children }) {
  return WithQuery(AppLayoutInner, CURRENT_USER_QUERY, null, {
    children,
  })();
}
