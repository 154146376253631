import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import Card from "../card/Card";
import InputField from "../fields/InputField";
import { SetOrgEin } from "../../services/organizations";
import EditOrgProfile from "../EditOrgProfile";
import ImpactApolloClient from "../../services/ImpactApolloClient";
import { MARK_ORG_ONBOARDING_COMPLETE_MUTATION } from "../../graphql/organizations";
import CharityCheckErrorBanner from "./CharityCheckErrorBanner";

// ________________________

const renderHqString = (org) => {
  const hqString = org?.city ? `${org?.city}, ${org?.state}` : "not listed";
  return hqString;
};

// ________________________

const OrgLookup = ({ ein, setEin }) => {
  return (
    <SimpleGrid columns={{ base: "1", md: "2" }} gap="20px">
      <InputField
        mb="0px"
        id="first"
        placeholder="12-3456789"
        label="EIN"
        value={ein}
        onChange={(e) => setEin(e.target.value)}
      />
    </SimpleGrid>
  );
};

const OrgConfirm = ({ org }) => {
  return (
    <SimpleGrid gap="20px">
      <Flex flexDirection="column" me={{ base: "0px", md: "100px" }}>
        <Text fontSize="xl" fontWeight="700" lineHeight="26px" mb={"10px"}>
          {org?.name} (EIN: {org?.ein})
        </Text>
      </Flex>
    </SimpleGrid>
  );
};

const OrgReview = ({ org }) => {
  return (
    <SimpleGrid gap="20px">
      <Flex flexDirection="column" me={{ base: "0px", md: "100px" }}>
        <Flex flexDirection="row" alignItems="flex-end">
          {org?.logoURL && (
            <img
              style={{ height: 100, width: "auto", marginRight: 15 }}
              src={org?.logoURL}
            />
          )}
          <Text fontSize="xl" fontWeight="700" lineHeight="26px" mb={"10px"}>
            {org?.name} (EIN: {org?.ein})
          </Text>
        </Flex>
        <Text
          color="secondaryGray.600"
          fontSize="md"
          fontWeight="400"
          lineHeight="26px"
        >
          <b>NTEE code:</b> {org?.candidNteeCode}
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize="md"
          fontWeight="400"
          lineHeight="26px"
        >
          <b>HQ:</b> {renderHqString(org)}
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize="md"
          fontWeight="400"
          lineHeight="26px"
        >
          <b>Mission:</b> {org?.mission}
        </Text>
      </Flex>
    </SimpleGrid>
  );
};

export default function OrgWizard({ organization, refetch }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  // Set based on API response
  const [activeStep, setActiveStep] = useState(organization ? 1 : 0);
  const [loading, setLoading] = useState(false);

  const [ein, setEin] = useState("");

  const [innerOrg, setInnerOrg] = useState(organization);

  React.useEffect(() => {
    if (organization) {
      setInnerOrg(organization);

      // compute the active step
      if (activeStep === 0) {
        setActiveStep(1);
      }
    }
  }, [organization, activeStep]);

  const steps = [
    {
      title: "Lookup",
      description:
        "Enter your organization's EIN and we'll grab more details so you don't have to!",
      content: <OrgLookup ein={ein} setEin={setEin} />,
      nextButtonText: "Continue",
      onNextButtonClick: () => {
        setLoading(true);

        SetOrgEin({ ein })
          .then((r) => {
            if (r && r.data) {
              setInnerOrg(r.data.setOrgEin);
              setActiveStep(activeStep + 1);
            }
            setInnerOrg(r.data.setOrgEin);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            Sentry.captureException(e);

            setLoading(false);
            alert("Error: " + e);
          });
      },
    },
    {
      title: "Confirm",
      description: "Does this look right?",
      content: <OrgConfirm org={innerOrg} />,
      prevButtonText: "No, go back",
      nextButtonText: "Yes, continue",
      onNextButtonClick: () => {
        setLoading(true);

        setTimeout(() => {
          setActiveStep(activeStep + 1);
          setLoading(false);
        }, 500);
      },
    },
    {
      title: "Review",
      description:
        "We've automatically pulled some more information from Candid. You can edit your nonprofit's profile at any time.",
      content: <OrgReview org={innerOrg} />,
    },
    {
      title: "Custom Profile",
      description:
        "Customize the appearance of your organization - you can change these settings at any time!",
      content: <EditOrgProfile org={innerOrg} isOnboarding />,
      nextButtonText: "Finish!",
      onNextButtonClick: () => {
        setLoading(true);
        ImpactApolloClient.mutate({
          mutation: MARK_ORG_ONBOARDING_COMPLETE_MUTATION,
        })
          .then((r) => {
            setLoading(false);

            if (refetch) {
              refetch();
            }
          })
          .catch((e) => {
            console.log(e);
            Sentry.captureException(e);

            setLoading(false);
            alert("Error: " + e);
          });
      },
    },
  ];

  const renderTab = (index) => {
    const completed = activeStep > index;
    const active = activeStep === index;

    return (
      <Tab
        focus="none"
        flex="1"
        style={{ cursor: "default" }}
        key={"tab-" + index}
      >
        <Flex
          direction="column"
          justify="center"
          align="center"
          position="relative"
          flex="1"
          _before={
            index === steps.length - 1
              ? null
              : {
                  content: "''",
                  width: "calc(100% + 16px)",
                  height: "3px",
                  bg: completed ? "white" : "#8476FF",
                  left: "calc(50% + 8px)",
                  top: "6px",
                  position: "absolute",
                  bottom: completed ? "40px" : "38px",
                  transition: "all .3s ease",
                }
          }
        >
          <Box
            zIndex="1"
            border="2px solid"
            borderColor={active || completed ? "white" : "#8476FF"}
            bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
            w="16px"
            h="16px"
            mb="8px"
            borderRadius="50%"
          />
          <Text
            color={active || completed ? "white" : "gray.300"}
            fontWeight={active ? "bold" : "normal"}
            display={{ sm: "none", md: "block" }}
          >
            {steps[index].title}
          </Text>
        </Flex>
      </Tab>
    );
  };

  const renderTabPanel = (index) => {
    const step = steps[index];
    return (
      <TabPanel p="0px" mx="40px" key={"tabpanel-" + index}>
        <Card p="30px">
          <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
            {step.title}
          </Text>
          <Text
            fontSize="md"
            color="gray.500"
            maxW={{
              base: "100%",
              md: "70%",
            }}
            fontWeight="400"
            mb="30px"
            lineHeight="28px"
            display={step?.description ? "block" : "none"}
          >
            {step?.description}
          </Text>
          <Flex direction="column" w="100%">
            <Stack direction="column" spacing="20px">
              {step.content}
            </Stack>
            <Flex justify="space-between" mt="34px">
              {index > 1 && (
                <Button
                  variant="light"
                  fontSize="sm"
                  borderRadius="16px"
                  w={{ base: "128px", md: "148px" }}
                  h="46px"
                  onClick={() => {
                    setActiveStep(index - 1);
                  }}
                >
                  {step?.prevButtonText || "Prev"}
                </Button>
              )}

              <Button
                variant="darkBrand"
                fontSize="sm"
                borderRadius="16px"
                w={{ base: "128px", md: "148px" }}
                h="46px"
                ms="auto"
                isLoading={loading}
                onClick={
                  step?.onNextButtonClick
                    ? step.onNextButtonClick
                    : () => {
                        setActiveStep(index + 1);
                      }
                }
              >
                {step?.nextButtonText || "Next"}
              </Button>
            </Flex>
          </Flex>
        </Card>
      </TabPanel>
    );
  };

  if (innerOrg && innerOrg?.charityCheckPasses === false) {
    // show error message

    return <CharityCheckErrorBanner />;
  }

  return (
    <Flex direction="column" align="center" position="relative">
      <Box
        bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
        position="absolute"
        w="100%"
        borderRadius="30px"
      >
        <Tabs
          variant="unstyled"
          zIndex="0"
          my={"45px"}
          display="flex"
          flexDirection="column"
          disabled
          index={activeStep}
        >
          <TabList
            display="flex"
            align="center"
            alignSelf="center"
            justifySelf="center"
            width="100%"
          >
            {steps.map((_, index) => renderTab(index))}
          </TabList>

          <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
            {steps.map((_, index) => renderTabPanel(index))}
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}
