import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import React from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import AuthLayout from "../../layouts/AuthLayout";
import logo from "../../assets/img/logo.png";
import {
  NonprofitTermsLink,
  PrivacyPolicyLink,
} from "../../components/TermsAndPolicyLinks";
import { Register } from "../../services/ImpactAuth";
import pricing from "../../assets/img/pricing.png";

function SignUp() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [termsChecked, setTermsChecked] = React.useState(false);

  const handleClick = () => setShow(!show);

  let navigate = useNavigate();

  const onCreateButtonClick = () => {
    if (!email || !password || !firstName || !lastName || !termsChecked) {
      alert("Please fill out all fields");
      return;
    }

    Register({
      email,
      password,
      firstName,
      lastName,
      termsConsentAccepted: termsChecked,
    })
      .then(() => {
        // navigate
        navigate(`/`);
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert("Login Error", e?.message);
      });
  };

  return (
    <AuthLayout>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "8vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          {logo && (
            <img
              src={logo}
              style={{ height: "35px", marginBottom: "34px" }}
              alt="logo"
            />
          )}
          <Heading
            color={textColor}
            fontSize={{ base: "34px", lg: "36px" }}
            mb="30px"
          >
            Create your account
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <SimpleGrid
              columns={{ base: "1", md: "2" }}
              gap={{ sm: "10px", md: "26px" }}
            >
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  First name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize="sm"
                  ms={{ base: "0px", md: "4px" }}
                  placeholder="First name"
                  variant="auth"
                  mb="24px"
                  size="lg"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Flex>
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Last name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  placeholder="Last name"
                  mb="24px"
                  size="lg"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </Flex>
            </SimpleGrid>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              type="email"
              placeholder="you@domain.com"
              mb="24px"
              size="lg"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              isRequired={true}
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "4px" }}
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Checkbox
              colorScheme="brand"
              mb="24px"
              onChange={(e) => {
                setTermsChecked(e.target.checked);
              }}
              isChecked={termsChecked}
            >
              <Text
                color={textColorDetails}
                fontSize="sm"
                fontWeight="400"
                ms="8px"
              >
                I have read and agree to Impact Local&apos;s{" "}
                <NonprofitTermsLink /> and&nbsp;
                <PrivacyPolicyLink />.
              </Text>
            </Checkbox>

            <Link
              fontWeight="500"
              color={textColor}
              href={pricing}
              target="_blank"
              fontSize="sm"
            >
              View pricing
            </Link>

            <Button
              variant="brand"
              fontSize="14px"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              mt="24px"
              onClick={onCreateButtonClick}
            >
              Create account
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="sm">
              Already a member?
              <NavLink to="/login">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  );
}

export default SignUp;
