import React from "react";

// Chakra imports
import { Button, Flex, Text } from "@chakra-ui/react";

export default function CreateOrgBanner({ createOrg }) {
  return (
    <Flex
      direction="column"
      bg="linear-gradient(135deg, #3311DB 0%, #7551FF 100%)"
      bgSize="cover"
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius="30px"
    >
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color="white"
        mb="14px"
        maxW={{
          base: "100%",
        }}
        fontWeight="700"
        lineHeight={{ base: "32px", md: "42px" }}
      >
        More than a new donation channel
      </Text>
      <Text
        fontSize="md"
        color="#E3DAFF"
        maxW={{
          base: "100%",
          md: "64%",
          lg: "40%",
          xl: "56%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight="500"
        mb="40px"
        lineHeight="28px"
      >
        Activating your nonprofit on Impact Local will allow your team to accept
        donations, create campaigns, and gain insight from passionate donors.
      </Text>
      <Flex align="center">
        <Button
          bg="white"
          color="black"
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight="500"
          fontSize="18px"
          py="25px"
          px="32"
          me="38px"
          onClick={createOrg}
        >
          Activate your nonprofit
        </Button>
      </Flex>
    </Flex>
  );
}
