import React, { useEffect, useState } from "react";
import {
  Spinner,
  Radio,
  RadioGroup,
  Stack,
  Flex,
  Text,
  Icon,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaCcStripe,
  FaCcApplePay,
} from "react-icons/fa";

import { MdEdit } from "react-icons/md";

import "./stripe.css";
import { GetActiveAppUrl } from "../../helpers/config";
import CheckoutAmountCard from "./CheckoutAmountCard";
import colors from "../../helpers/colors";
import ImpactApolloClient from "../../services/ImpactApolloClient";

import {
  COMPLETE_DONATION_CHECKOUT_SESSION_MUTATION,
  START_ADD_NEW_PAYMENT_MUTATION,
} from "../../graphql/checkout";

export const PaymentMethodIcon = (brand, props) => {
  if (!brand) {
    return null;
  }
  switch (brand.toLowerCase()) {
    case "visa":
      return <Icon as={FaCcVisa} {...props} />;
    case "mastercard":
      return <Icon as={FaCcMastercard} {...props} />;
    case "amex":
      return <Icon as={FaCcAmex} {...props} />;
    case "discover":
      return <Icon as={FaCcDiscover} {...props} />;
    case "apple pay":
      return <Icon as={FaCcApplePay} {...props} />;
    default:
      return <Icon as={FaCcStripe} {...props} />;
  }
};

export default function ConfirmDonateV2Form({
  stripe,
  elements,
  publicOrgInfo,
  donationAmountCents,
  transactionCostsCents,
  grandTotalCents,
  coverTransactionCosts,
  isSubscription,
  checkoutSessionToken,
  paymentMethods,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    paymentMethods?.length ? paymentMethods[0].stripeId : null
  );

  useEffect(() => {
    if (paymentMethods?.length && !selectedPaymentMethodId) {
      setSelectedPaymentMethodId(paymentMethods[0].stripeId);
    }
  }, [paymentMethods, selectedPaymentMethodId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedPaymentMethodId) {
      alert("Please select a payment method.");
      return;
    }

    setIsLoading(true);

    // confirm payment on backend

    ImpactApolloClient.mutate({
      mutation: COMPLETE_DONATION_CHECKOUT_SESSION_MUTATION,
      variables: {
        token: checkoutSessionToken,
        platformStripePaymentMethodId: selectedPaymentMethodId,
      },
    })
      .then((res) => {
        console.log(res);

        setIsLoading(false);

        // refresh page
        window.location.reload(false);
      })
      .catch((err) => {
        console.log("err", err);
        Sentry.captureException(err);

        setErrorMessage(
          "There was an error processing your donation. Please try again. " +
            err
        );

        setIsLoading(false);
      });
  };

  const onAddNewPaymentClick = () => {
    ImpactApolloClient.mutate({
      mutation: START_ADD_NEW_PAYMENT_MUTATION,
      variables: {
        token: checkoutSessionToken,
      },
    })
      .then((res) => {
        console.log(res);

        // refresh page
        window.location.href = res.data.startAddNewPayment;
      })
      .catch((err) => {
        console.log("err", err);
        Sentry.captureException(err);

        setErrorMessage("There was an error, please try again. " + err);
      });
  };

  const renderPaymentMethods = () => {
    return (
      <Flex w="100%" mb="22px" flexDir={"column"}>
        <Text fontSize="lg" fontWeight="600" mb="10px">
          Payment Method
        </Text>
        <RadioGroup
          onChange={setSelectedPaymentMethodId}
          value={selectedPaymentMethodId}
          px="10px"
          py="10px"
        >
          <Stack direction="column">
            {paymentMethods.map((paymentMethod) => (
              <Radio
                key={paymentMethod.stripeId}
                value={paymentMethod.stripeId}
              >
                <Flex w="100%" alignItems={"flex-start"} flex={1}>
                  {PaymentMethodIcon(paymentMethod.name, {
                    style: { marginRight: 10, marginLeft: 5, fontSize: 24 },
                  })}{" "}
                  {paymentMethod.name.charAt(0).toUpperCase() +
                    paymentMethod.name.slice(1)}{" "}
                  ending in {paymentMethod.last4}
                </Flex>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <Flex w="100%" mt="10px" mb="10px" pl="10px">
          <p
            onClick={onAddNewPaymentClick}
            rel="noopener noreferrer"
            style={{
              color: colors.blue,
              fontWeight: "600",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Add New Payment Method
          </p>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <CheckoutAmountCard
        publicOrgInfo={publicOrgInfo}
        donationAmountCents={donationAmountCents}
        transactionCostsCents={transactionCostsCents}
        grandTotalCents={grandTotalCents}
        coverTransactionCosts={coverTransactionCosts}
        isSubscription={isSubscription}
      />
      <form id="payment-form">
        {errorMessage && (
          <div
            id="payment-message"
            style={{
              color: colors.red,
              marginTop: 0,
              marginBottom: 10,
              paddingTop: 0,
            }}
          >
            {errorMessage}
          </div>
        )}
        {renderPaymentMethods()}
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="submit-button"
          onClick={handleSubmit}
        >
          <span id="button-text">
            {isLoading ? (
              <Spinner size="sm" color="primary.500" />
            ) : (
              "Complete Donation"
            )}
          </span>
        </button>
      </form>
      <a
        style={{
          color: colors.blue,
          fontWeight: "600",
          cursor: "pointer",
          paddingBottom: 30,
        }}
        href="impactlocal://editdonation"
      >
        <Icon as={MdEdit} color={colors.blue} /> Edit Your Donation
      </a>
    </>
  );
}
