import React from "react";
import { Icon, Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { MdArrowForward } from "react-icons/md";
import { TbCalendarRepeat } from "react-icons/tb";

import { formatAmountCents } from "../../helpers/strings";
import colors from "../../helpers/colors";

const styles = {
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
  },
  arrowIcon: {
    width: 24,
    height: 24,
    marginLeft: 15,
    marginRight: 15,
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    zIndex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 12,
  },
  cardContentRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: 4,
    paddingBottom: 4,
  },
  cardTextLeft: {
    color: colors.lightBlack,
    fontSize: 15,
    fontWeight: "600",
    flex: 1,
  },
  cardTextRight: {
    color: colors.lightBlack,
    fontSize: 15,
    fontWeight: "400",
  },
  donationAmountTitle: {
    color: colors.lightBlack,
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
  },
  coverContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 20,
    marginTop: 25,
    borderTopColor: colors.grey,
    borderTopWidth: 1,
  },
  coverTitle: {
    textAlign: "center",
    color: colors.lightBlack,
    marginBottom: 15,
    fontSize: 24,
    fontWeight: "500",
  },
  coverText: {
    fontSize: 15,
    fontWeight: "400",
    textAlign: "center",
  },
};

const DonationSuccessCard = ({
  publicOrgInfo,
  grandTotalCents,
  paymentIntent,
  isSubscription,
}) => {
  console.log(paymentIntent);

  const createdMoment = moment.unix(paymentIntent.created);
  const createdDate = createdMoment.format("MMM D, YYYY");

  return (
    <Box className="cardbox">
      <Box style={styles.cardHeader}>
        <Box style={styles.topSection}>
          <Text style={styles.donationAmountTitle} className="poppins">
            {formatAmountCents(grandTotalCents)}
          </Text>

          <Icon
            as={MdArrowForward}
            color={colors.lightBlack}
            style={styles.arrowIcon}
          />

          <img
            style={{ height: 70, width: "auto" }}
            alt="logo"
            src={publicOrgInfo?.logoURL}
          />
        </Box>
      </Box>

      <Box style={styles.cardContent}>
        <Box style={styles.cardContentRow}>
          <Text style={styles.cardTextLeft}>Nonprofit</Text>
          <Text style={styles.cardTextRight}>{publicOrgInfo?.name}</Text>
        </Box>
        <Box style={styles.cardContentRow}>
          <Text style={styles.cardTextLeft}>EIN</Text>
          <Text style={styles.cardTextRight}>{publicOrgInfo?.ein}</Text>
        </Box>
        <Box style={styles.cardContentRow}>
          <Text style={styles.cardTextLeft}>Total charged</Text>
          <Text style={styles.cardTextRight}>
            {formatAmountCents(grandTotalCents)}
          </Text>
        </Box>
        <Box style={styles.cardContentRow}>
          <Text style={styles.cardTextLeft}>Date</Text>
          <Text style={styles.cardTextRight}>{createdDate}</Text>
        </Box>
        {isSubscription && (
          <Box style={styles.cardContentRow}>
            <Text style={styles.cardTextLeft}>Frequency</Text>
            <Text style={styles.cardTextRight}>Monthly</Text>
          </Box>
        )}
      </Box>

      <Box style={styles.coverContent}>
        <Text style={styles.coverTitle}>
          {isSubscription && (
            <Icon
              as={TbCalendarRepeat}
              color={colors.lightBlack}
              style={{
                width: 21,
                height: 21,
                marginRight: 10,
              }}
            />
          )}
          {isSubscription ? "Impact Subscription" : "Donation"} Confirmed!
        </Text>
        <Text style={styles.coverText}>
          {publicOrgInfo?.postDonationMessage
            ? publicOrgInfo.postDonationMessage
            : `Thank you for your support! You will receive a receipt via
          email shortly.`}
        </Text>
      </Box>
    </Box>
  );
};

export default DonationSuccessCard;
