import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { TERMS_LINKS } from "../helpers/config";

const terms = TERMS_LINKS;

const renderTerm = ({ link, title }, textColor, isList) => {
  const item = (
    <Link
      fontWeight="500"
      color={textColor}
      href={link}
      key={link}
      target="_blank"
      display="flex"
    >
      {title}
    </Link>
  );
  if (isList) {
    return (
      <ListItem
        me={{
          base: "10px",
          md: "44px",
        }}
        key={link}
        _last={{ me: "0px" }}
      >
        {item}
      </ListItem>
    );
  }
  return item;
};

export const UserTermsLink = () => {
  return renderTerm(terms.user);
};

export const PrivacyPolicyLink = () => {
  return renderTerm(terms.privacy);
};

export const NonprofitTermsLink = () => {
  return renderTerm(terms.nonprofit);
};

export const DoNotSellLink = () => {
  return renderTerm(terms.donotsell);
};

export default function TermsAndPolicyLinks({ color }) {
  const textColor = useColorModeValue("gray.400", "white");

  const termsArray = Object.values(terms);

  return termsArray.map((term) => renderTerm(term, color || textColor, true));
}
