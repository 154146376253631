import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Flex } from "@chakra-ui/react";

import DonateLayout from "../../layouts/DonateLayout";
import { STRIPE_PUBKEY } from "../../helpers/config";
import WithQuery from "../../components/WithQuery";
import { PUBLIC_ORG_INFO_QUERY } from "../../graphql/organizations";
import SetupForm from "../../components/stripe/SetupForm";

//   get url parameters payment_intent and token
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");

const orgId = urlParams.get("org_id");
let coverTransactionCosts = urlParams.get("cover_transaction_costs");
let totalTransactionCost = urlParams.get("total_transaction_cost");
let grandTotal = urlParams.get("grand_total");
let donationAmount = urlParams.get("donation_amount");

coverTransactionCosts = Boolean(coverTransactionCosts);
totalTransactionCost = Number(totalTransactionCost);
grandTotal = Number(grandTotal);
donationAmount = Number(donationAmount);

const stripePromise = loadStripe(STRIPE_PUBKEY);

function SetupInner({ data, loading, error }) {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: token,
    appearance,
  };

  const publicOrgInfo = data?.publicOrgInfo;

  return (
    <Elements stripe={stripePromise} options={options}>
      <DonateLayout>
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <SetupForm
            orgId={orgId}
            coverTransactionCosts={coverTransactionCosts}
            totalTransactionCost={totalTransactionCost}
            clientSecret={token}
            publicOrgInfo={publicOrgInfo}
            grandTotal={grandTotal}
            donationAmount={donationAmount}
          />
        </Flex>
      </DonateLayout>
    </Elements>
  );
}

export default function Setup({ children }) {
  return WithQuery(
    SetupInner,
    PUBLIC_ORG_INFO_QUERY,
    { id: orgId },
    {
      children,
    }
  )();
}
