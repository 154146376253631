import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Flex, Spinner } from "@chakra-ui/react";

import DonateLayout from "../../layouts/DonateLayout";
import { STRIPE_PUBKEY } from "../../helpers/config";
import WithQuery from "../../components/WithQuery";
import { CHECKOUT_SESSION_QUERY } from "../../graphql/checkout";
import SetupForm from "../../components/stripe/SetupForm";
import CheckoutAmountCard from "../../components/stripe/CheckoutAmountCard";

//   get url parameters payment_intent and token
const urlParams = new URLSearchParams(window.location.search);

// checkout session token
const checkoutSessionToken = urlParams.get("token");
const platformAccount = urlParams.get("platform_account");
const setupIntentClientSecret = urlParams.get("setup_intent_client_secret");

// if creating a new payment method, this should be the platform account id
const stripePromise = loadStripe(STRIPE_PUBKEY, {
  stripeAccount: platformAccount,
});

function DonateV2AddPaymentPageInner({ data, loading, error }) {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: setupIntentClientSecret,
    appearance,
  };

  const checkoutSession = data?.donationCheckoutSession;
  const publicOrgInfo = checkoutSession?.publicOrgInfo;

  const paymentAmountCents = checkoutSession?.paymentAmountCents;
  const coverTransactionCosts = checkoutSession?.coverTransactionCosts;
  const finalAmountToCharge = checkoutSession?.finalAmountToCharge;
  const transactionCost = checkoutSession?.transactionCost;

  const isSubscription = checkoutSession?.isSubscription;

  return (
    <Elements stripe={stripePromise} options={options}>
      <DonateLayout>
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {loading ? (
            <Spinner size="sm" color="primary.500" />
          ) : (
            <>
              <CheckoutAmountCard
                publicOrgInfo={publicOrgInfo}
                donationAmountCents={paymentAmountCents}
                transactionCostsCents={transactionCost}
                grandTotalCents={finalAmountToCharge}
                coverTransactionCosts={coverTransactionCosts}
                isSubscription={isSubscription}
              />

              <SetupForm clientSecret={setupIntentClientSecret} />
            </>
          )}
        </Flex>
      </DonateLayout>
    </Elements>
  );
}

export default function DonateV2AddPaymentPage({ children }) {
  return WithQuery(
    DonateV2AddPaymentPageInner,
    CHECKOUT_SESSION_QUERY,
    { token: checkoutSessionToken },
    {
      children,
    }
  )();
}
