import React from "react";
import { NavLink } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import AuthLayout from "../../layouts/AuthLayout";

import { Login } from "../../services/ImpactAuth";
import logo from "../../assets/img/logo.png";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  let navigate = useNavigate();

  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const onLoginButtonPess = () => {
    if (!email || !password) {
      alert("Invalid credentials, please check your login information.");

      return;
    }

    setLoading(true);

    Login({ email, password })
      .then(() => {
        // navigate
        navigate(`/`);
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert("Login Error", e?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          {logo && (
            <img
              src={logo}
              style={{ height: "35px", marginBottom: "34px" }}
              alt="logo"
            />
          )}
          <Heading color={textColor} fontSize="36px" mb="30px">
            Sign in
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="you@domain.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />

            <Flex justifyContent="space-between" align="flex-start">
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                isRequired={true}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <NavLink to="/reset">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <InputGroup size="md">
              <Input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={onLoginButtonPess}
              isLoading={loading}
            >
              Sign in
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Don't have an account?
              <NavLink to="/register">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign up
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  );
}

export default SignIn;
