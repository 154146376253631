import {
  Box,
  Flex,
  Stack,
  ListItem,
  Text,
  ColorModeScript,
} from "@chakra-ui/react";

import Brand from "./Brand";
import Links from "./Links";
import React from "react";
import { Logout } from "../../../services/ImpactAuth";

function SidebarContent({ showAccountLinks }) {
  return (
    <Flex direction="column" minH="100%" height="max-content" pt="40px">
      <Brand />
      <Stack direction="column" mb="auto" mt="40px">
        <Box ps="35px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links showAccountLinks={showAccountLinks} />
        </Box>
        <Box
          ps="35px"
          pe={{ md: "16px", "2xl": "1px" }}
          display={{ md: "none" }}
          onClick={Logout}
          cursor="pointer"
        >
          <Flex ps="34px" alignItems="center" mb="8px" mt="20px">
            <Text color="red.400" fontWeight="500" fontSize="sm" ml="15px">
              Log out
            </Text>
          </Flex>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
