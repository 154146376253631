const colors = {
  none: null,
  black: "#000",
  white: "#fff",
  greyLight: "#F7F9FC",
  grey: "#EDF1F7",
  greyDark: "#8F9BB3",

  lightBlack: "#373435",
  lighterBlack: "#6A6668",
  lightestBlack: "#E5E6E8",

  yellow: "#FABE33",
  green: "#08A88B",
  red: "#F75964",
  blue: "#00BBFF",

  primary: "#4D58A8",
  primaryLight: "#474F9E",
  primaryDark: "#2E3A59",
  primaryShadow: "#A6C1FF",
  primaryShadowRgba: "rgba(166, 193, 255, 0.2)",
  transparent: "transparent",
};

export default colors;
