import { gql } from "@apollo/client";

export const ORG_FRAGMENT = gql`
  fragment OrgFragment on Organization {
    id

    ein
    name
    tagline
    mission
    websiteURL

    streetAddress1
    streetAddress2
    city
    state
    zipCode
    latLng

    overrideStreetAddress1
    overrideStreetAddress2
    overrideCity
    overrideState
    overrideZipCode

    candidId
    candidNteeCode
    candidProfileLevel
    candidReportURL
    candidSubsectionCode
    candidNumberEmployees

    verified
    logoURL
    postDonationMessage
    completedOnboardingAt
    stripeAccountId

    candidPremierBlob

    charityCheckPasses
  }
`;

export const PUBLIC_ORG_INFO_QUERY = gql`
  query PublicOrgInfo($id: ID!) {
    publicOrgInfo(id: $id) {
      id
      name
      ein
      logoURL
      tagline
      postDonationMessage
    }
  }
`;

export const SET_ORG_EIN_MUTATION = gql`
  ${ORG_FRAGMENT}
  mutation SetOrgEin($ein: String!) {
    setOrgEin(ein: $ein) {
      ...OrgFragment
    }
  }
`;

export const MARK_ORG_ONBOARDING_COMPLETE_MUTATION = gql`
  ${ORG_FRAGMENT}
  mutation MarkOrgOnboardingComplete {
    markOrgOnboardingComplete {
      ...OrgFragment
    }
  }
`;

export const UPDATE_ORG_PROFILE_MUTATION = gql`
  ${ORG_FRAGMENT}
  mutation UpdateOrgProfile($orgProfileUpdateInput: OrgProfileUpdateInput!) {
    updateOrgProfile(orgProfileUpdateInput: $orgProfileUpdateInput) {
      ...OrgFragment
    }
  }
`;

export const ORG_DONATION_HISTORY_QUERY = gql`
  query OrganizationDonationHistory {
    organizationDonationHistory {
      id
      stripePaymentIntentId

      donationAmountCents
      totalTransactionFeesCents
      totalChargeCents
      userCoveredFees
      status

      billingPostalCode

      createdAt

      userReport {
        firstName
        lastName
        email
        communicationOptInAt
      }
    }
  }
`;
