import { gql } from "@apollo/client";

export const DONATION_CHECKOUT_SESSION_FRAGMENT = gql`
  fragment DonationCheckoutSessionFragment on DonationCheckoutSession {
    id
    orgId
    userId

    stripePaymentIntentId
    stripePaymentIntentClientSecret

    paymentAmountCents
    coverTransactionCosts
    isSubscription
    impactLocalPlatformFee
    finalAmountToCharge
    transactionCost
    impactLocalPlatformFeeRate
    stripePercentageFeeRate

    publicOrgInfo {
      id
      name
      ein
      logoURL
      tagline
      postDonationMessage
    }

    paymentMethods {
      stripeId
      name
      last4
    }
  }
`;

export const CHECKOUT_SESSION_QUERY = gql`
  ${DONATION_CHECKOUT_SESSION_FRAGMENT}

  query DonationCheckoutSession($token: String!) {
    donationCheckoutSession(token: $token) {
      ...DonationCheckoutSessionFragment
    }
  }
`;

export const COMPLETE_DONATION_CHECKOUT_SESSION_MUTATION = gql`
  ${DONATION_CHECKOUT_SESSION_FRAGMENT}

  mutation CompleteDonationCheckoutSession(
    $token: String!
    $platformStripePaymentMethodId: String!
  ) {
    completeDonationCheckoutSession(
      token: $token
      platformStripePaymentMethodId: $platformStripePaymentMethodId
    ) {
      ...DonationCheckoutSessionFragment
    }
  }
`;

export const START_ADD_NEW_PAYMENT_MUTATION = gql`
  mutation StartAddNewPayment($token: String!) {
    startAddNewPayment(token: $token)
  }
`;
