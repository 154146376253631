import { SET_ORG_EIN_MUTATION } from "../graphql/organizations";
import ImpactApolloClient from "./ImpactApolloClient";

export async function SetOrgEin({ ein }) {
  return ImpactApolloClient.mutate({
    mutation: SET_ORG_EIN_MUTATION,
    variables: {
      ein,
    },
  });
}
