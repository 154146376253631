import React from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

import AuthLayout from "../../layouts/AuthLayout";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { RESET_PASSWORD_MUTATION } from "../../graphql/auth";
import ImpactApolloClient from "../../services/ImpactApolloClient";

function ForgotPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [email, setEmail] = React.useState();

  const [submitted, setSubmitted] = React.useState(false);

  const onResetPasswordButtonPress = () => {
    if (!email) {
      return;
    }

    ImpactApolloClient.mutate({
      mutation: RESET_PASSWORD_MUTATION,
      variables: {
        email,
      },
    })
      .then((res) => {
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err);

        alert("Error", "There was an error with your request: " + err?.message);
      });
  };

  return (
    <AuthLayout>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px", lg: "100px", xl: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", lg: "16vh", xl: "20vh" }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          {logo && (
            <img
              src={logo}
              style={{ height: "35px", marginBottom: "34px" }}
              alt="logo"
            />
          )}

          <Heading
            color={textColor}
            fontSize={{ base: "3xl", md: "36px" }}
            mb="16px"
          >
            Reset your password
          </Heading>
          <Text
            color={textColorSecondary}
            fontSize="md"
            w={{ base: "100%", lg: "456px" }}
            maxW="100%"
          >
            {submitted
              ? `Check your email for a link to reset your password.`
              : `Enter the email address associated with your account and we'll send
              you a link to reset your password.`}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", lg: "456px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
          align="start"
        >
          {!submitted && (
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="you@domain.com"
                mb="24px"
                size="lg"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                onClick={onResetPasswordButtonPress}
              >
                Continue
              </Button>
            </FormControl>
          )}
          <NavLink to="/login">
            <Text
              color={textColorBrand}
              as="span"
              ms="5px"
              fontWeight="500"
              fontSize="14px"
            >
              Return to sign in
            </Text>
          </NavLink>
        </Flex>
      </Flex>
    </AuthLayout>
  );
}

export default ForgotPassword;
