import React from "react";
import { Icon, Box, Text } from "@chakra-ui/react";

import { formatAmountCents } from "../../helpers/strings";
import colors from "../../helpers/colors";
import { MdArrowForward } from "react-icons/md";

const styles = {
  flex: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
  },
  arrowIcon: {
    width: 24,
    height: 24,
    marginLeft: 15,
    marginRight: 15,
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    zIndex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 12,
  },
  cardContentRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: 4,
    paddingBottom: 4,
  },
  cardTextLeft: {
    color: colors.lightBlack,
    fontSize: 15,
    fontWeight: "600",
    flex: 1,
  },
  cardTextRight: {
    color: colors.lightBlack,
    fontSize: 15,
    fontWeight: "600",
  },
  donationAmountTitle: {
    color: colors.lightBlack,
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
  },
};

const CheckoutAmountCard = ({
  publicOrgInfo,
  donationAmountCents,
  transactionCostsCents,
  grandTotalCents,
  coverTransactionCosts,
  isSubscription,
}) => {
  return (
    <Box className="cardbox">
      <Box style={styles.cardHeader}>
        <Box style={styles.topSection}>
          <Text style={styles.donationAmountTitle}>
            {formatAmountCents(donationAmountCents)}
          </Text>

          <Icon
            as={MdArrowForward}
            color={colors.primaryDark}
            style={styles.arrowIcon}
          />

          <img
            style={{ height: 70, width: "auto" }}
            alt="logo"
            src={publicOrgInfo?.logoURL}
          />
        </Box>
      </Box>

      <Box style={styles.cardContent}>
        <Box style={styles.cardContentRow}>
          <Text style={styles.cardTextLeft}>Total Donation</Text>
          <Text style={styles.cardTextRight}>
            {formatAmountCents(donationAmountCents)}
          </Text>
        </Box>
        {coverTransactionCosts && (
          <Box style={styles.cardContentRow}>
            <Text style={styles.cardTextLeft}>Transaction Costs</Text>
            <Text style={styles.cardTextRight}>
              {formatAmountCents(transactionCostsCents)}
            </Text>
          </Box>
        )}
        {isSubscription && (
          <Box style={styles.cardContentRow}>
            <Text style={styles.cardTextLeft}>Frequency</Text>
            <Text style={styles.cardTextRight}>Monthly</Text>
          </Box>
        )}

        <Box
          style={{
            ...styles.cardContentRow,
            borderTopColor: colors.grey,
            borderTopWidth: 1,
            paddingTop: 10,
            marginTop: 10,
          }}
        >
          <Text style={styles.cardTextLeft}> Total to be charged</Text>
          <Text style={styles.cardTextRight}>
            {formatAmountCents(grandTotalCents)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutAmountCard;
